import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { OtasErrors } from 'app/shared/model/admin/ota.model';

@Injectable({
  providedIn: 'root',
})
export class AdminCustomerService {
  constructor(private http: HttpClient) {}

  getOtas(customerId, isFromConfiguration = false) {
    const url = `${environment.onlinePath}customers/${customerId}/otas`;
    let params: HttpParams = new HttpParams();
    if (isFromConfiguration) {
      params = params.set('configuration', 'true');
    }
    return this.http.get(url, { params });
  }

  getChainOtas(customerIds) {
    const url = `${environment.onlinePath}customers/otas`;
    const params = new HttpParams().set('customer_id', customerIds);
    return this.http.get(url, { params });
  }

  getChainOauthStatus(customerIds, otasIds) {
    const url = `${environment.onlinePath}interchains/otas/config/oauth/status`;
    const params = new HttpParams().set('customers_ids', customerIds).set('otas_ids', otasIds);
    return this.http.get(url, { params });
  }

  getOta(customerId, otaId) {
    const url = `${environment.onlinePath}customers/${customerId}/otas/${otaId}`;
    const params: HttpParams = new HttpParams();
    return this.http.get(url, { params: params });
  }

  saveOta(customerId, ota) {
    const url = `${environment.onlinePath}customers/${customerId}/otas/${ota.id}`;
    return this.http.put(url, ota);
  }

  saveOtas(customerId, otas) {
    const url = `${environment.onlinePath}customers/${customerId}/otas`;
    return this.http.patch(url, otas);
  }

  getOtasErrors(customerId) {
    const url = `${environment.onlinePath}customers/${customerId}/otas/problems`;
    return this.http.get<OtasErrors[]>(url).pipe(
      map((val) => {
        if (val) {
          return OtasErrors.createFromListResponse(val);
        }
      }),
    );
  }

  getChainOtasErrors(customerIds) {
    const url = `${environment.onlinePath}customers/otas/problems`;
    const params = new HttpParams().set('customer_id', customerIds);
    return this.http.get(url, { params });
  }

  getChainOtasLastRead(customerIds, otasIds) {
    const url = `${environment.onlinePath}interchains/otas/last/read`;
    const params = new HttpParams().set('customers_ids', customerIds).set('otas_ids', otasIds);
    return this.http.get(url, { params });
  }

  validateOta(customer, otaId, otaUrl) {
    const url = `${environment.onlinePath}customers/${customer.id}/otas/${otaId}`;
    const body = { url: otaUrl };
    return this.http.post(url, body);
  }
}
