import { LogsTimelineComponent } from './logs/logs-timeline/logs-timeline.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import {
  SessionThemeComponent,
  PublicThemeComponent,
  PrestayThemeComponent,
  CollectThemeComponent,
} from './layouts/themes/index';
import { ProfileComponent } from './configuration/profile/profile.component';
import { UsersComponent } from './configuration/users/users.component';

import { LoginComponent, RecoveryComponent } from './login/index';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthGuard, AuthPrestayGuard } from './_guards/index';
import { SurveyThemeComponent } from './layouts/themes/survey/survey-theme/survey-theme.component';
import { PrivateDownloadComponent } from './prestay/private-download/private-download.component';
import { MhLibComponent } from './mh-lib/mh-lib.component';
import { DownloadPageComponent } from './download-page/download-page.component';
import { GestorUsuariosComponent } from './new-fs/gestor-usuarios/gestor-usuarios.component';
import { InvoiceComponent } from './new-fs/invoice/invoice.component';
import { PublicPortalComponent } from './new-fs/voices/public-portal/public-portal.component';
import { HubspotSupportComponent } from './support/hubspot-support/hubspot-support.component';
import { VoicesComponent } from './new-fs/voices/voices.component';
import { NotificationsCentralComponent } from './notifications-central/notifications-central.component';
import { NewRootDasboardComponent } from './dashboard/new-root-dashboard/new-root-dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: SessionThemeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        component: NewRootDasboardComponent,
      },
      {
        path: 'soporte',
        component: HubspotSupportComponent,
      },
      {
        path: 'notifications-central',
        component: NotificationsCentralComponent,
      },
      {
        path: 'followup',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./new-fs/followup-onsite/followup-onsite.module').then((m) => m.FollowupOnsiteModule),
      },
      {
        path: 'online',
        canActivate: [AuthGuard],
        loadChildren: () => import('./online/online.module').then((m) => m.OnlineModule),
      },
      {
        path: 'intercadena',
        canActivate: [AuthGuard],
        loadChildren: () => import('./inter-chain/inter-chain.module').then((m) => m.InterChainModule),
      },
      {
        path: 'prestay',
        canActivate: [AuthGuard],
        canLoad: [AuthPrestayGuard],
        loadChildren: () => import('./prestay/prestay.module').then((m) => m.PrestayModule),
      },
      {
        path: 'customer',
        canActivate: [AuthGuard],
        loadChildren: () => import('./customer/src/customer.module').then((m) => m.CustomerModule),
      },
      {
        path: 'onsite',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./new-fs/followup-onsite/followup-onsite-2.module').then((m) => m.FollowupOnsiteModule),
      },
      {
        path: 'collect',
        canActivate: [AuthGuard],
        loadChildren: () => import('./collect/collect.module').then((m) => m.CollectModule),
      },
      {
        path: 'perfil',
        canActivate: [AuthGuard],
        component: ProfileComponent,
      },
      {
        path: 'usuarios/:page',
        canActivate: [AuthGuard],
        component: UsersComponent,
      },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'logs',
        canActivate: [AuthGuard],
        component: LogsTimelineComponent,
      },
      {
        path: 'cases',
        canActivate: [AuthGuard],
        loadChildren: () => import('./cases/cases.module').then((m) => m.CasesModule),
      },
      {
        path: 'semantic',
        canActivate: [AuthGuard],
        loadChildren: () => import('./semantic/semantic.module').then((m) => m.SemanticModule),
      },
      {
        path: 'new-fs',
        canActivate: [AuthGuard],
        loadChildren: () => import('./new-fs/new-fs.module').then((m) => m.NewFsModule),
      },
      {
        path: 'invoice',
        canActivate: [AuthGuard],
        component: InvoiceComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('./new-fs/invoice/invoice.module').then((m) => m.InvoiceModule),
          },
        ],
      },
      {
        path: 'gestor-usuarios',
        component: GestorUsuariosComponent,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./new-fs/gestor-usuarios/gestor-usuarios.module').then((m) => m.GestorUsuariosModule),
          },
        ],
      },
      {
        path: 'gestor-usuarios-global',
        component: GestorUsuariosComponent,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./new-fs/gestor-usuarios/gestor-usuarios.module').then((m) => m.GestorUsuariosModule),
          },
        ],
      },
      {
        path: 'secure/file/download/:nick_id/:filename',
        component: PrivateDownloadComponent,
      },
      {
        path: 'voice',
        component: VoicesComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('./new-fs/voices/voices.module').then((m) => m.VoicesModule),
          },
        ],
      },
    ],
  },

  {
    path: ':hotel_nickname/prestay',
    component: PrestayThemeComponent,
    loadChildren: () => import('./prestay/prestay.module').then((m) => m.PrestayModule),
  },

  {
    path: ':hotel_nickname/collect',
    component: CollectThemeComponent,
    loadChildren: () => import('./collect/collect.module').then((m) => m.CollectModule),
  },

  {
    path: 'survey',
    component: SurveyThemeComponent,
    loadChildren: () => import('./features/surveys/surveys.module').then((m) => m.SurveysModule),
  },

  {
    path: 'survey-public',
    loadChildren: () => import('./survey-public/survey-public.module').then((m) => m.SurveyPublicModule),
  },
  {
    path: 'library',
    component: MhLibComponent,
  },
  {
    path: 'download/:filename',
    component: DownloadPageComponent,
  },
  {
    path: 'voices/:token',
    component: PublicPortalComponent,
    loadChildren: () => import('./new-fs/voices/voices.module').then((m) => m.VoicesModule),
  },
  {
    path: '',
    component: PublicThemeComponent,
    children: [
      { path: 'logout', redirectTo: 'login', pathMatch: 'full' },
      { path: 'fidelity/login', component: LoginComponent },
      { path: 'login', component: LoginComponent },
      { path: 'recovery', component: RecoveryComponent },
      { path: 'recordar-contrasena', component: ForgotPasswordComponent },
      { path: '**', component: NotFoundComponent }, // Siempre debe ir al último
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthPrestayGuard],
})
export class AppRoutingModule {}
