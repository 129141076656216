import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { CoreLibModalComponent } from '../modal/modal.component';
import { SurveyAnswerService } from 'app/shared/surveys/survey-answer.service';
import { SurveysService } from 'app/core-lib/services/surveys/surveys.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointService } from 'app/core-lib/services/breakpoints/breakpoint.service';
import { Router } from '@angular/router';
import { Survey } from 'app/shared/model';
import { SmartRepliesService } from 'app/new-fs/smart-replies/smart-replies.service';
import { Store } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';
import { environment } from 'src/environments/environment';
import { Clipboard } from '@angular/cdk/clipboard';
import { AlertService } from 'app/core-lib/services/alerts/alert.service';

const MIN_HEIGHT = 800;

@Component({
  selector: 'mh-core-lib-survey-detail-modal',
  templateUrl: './survey-detail-modal.component.html',
  styleUrls: ['./survey-detail-modal.component.scss'],
  providers: [SurveyAnswerService],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('500ms', style({ transform: 'translateY(0)' })),
      ]),
      transition(':leave', [animate('500ms', style({ transform: 'translateY(100%)' }))]),
    ]),
  ],
})
export class CoreLibSurveyDetailModalComponent extends CoreLibModalComponent implements OnInit {
  surveyOpen = true;
  commentsOpen = false;
  comments = [];
  answersComments = [];
  dataIsReady = false;
  surveyData = null;
  downloadingPDF = false;
  tabIndex = 0;
  answerModal: DialogRef;
  showContact = false;
  showLoader = false;
  smartReplyText: string;
  customerFrom: string;
  surveyId: number;
  answerId: number;
  messageRegistered = null;
  limitExceededError = true;
  limitExceeded;
  srMonthLimit;
  subject: string;
  surveyToFormat: Survey;
  formattedSurvey: Survey;
  messagesQty: number;
  triggerActionsCardOrigin: ElementRef;
  shareActionsOpen = false;
  guestUrl;
  surveyUrl;

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private surveyAnswerService: SurveyAnswerService,
    private surveysService: SurveysService,
    private breakpointService: BreakpointService,
    private router: Router,
    private smartRepliesService: SmartRepliesService,
    private store: Store,
    private clipboard: Clipboard,
    private alertService: AlertService,
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.formatSurvey();
    this.init();
  }

  handleMessagesQty($event) {
    this.messagesQty = $event;
  }

  init() {
    this.surveyId = this.data.survey.answered_survey.survey_id;
    this.answerId = this.data.survey.answered_survey.id;
    this.fetchComments(this.surveyId, this.answerId);
    if (this.data?.type === 'contact') {
      this.showContact = true;
      this.showLoader = true;
      this.getMailingData(this.answerId);
      const customerState = this.store.selectSnapshot(CustomersState.currentCustomer);
      this.smartRepliesService.getSmartReplyUsage(customerState.id).subscribe((resp) => {
        this.limitExceededError = !resp.can_generate_more;
        this.srMonthLimit = resp.can_generate_more ? null : resp.monthly_limit;
        this.getSmartReply(this.surveyId, this.answerId);
      });
    }
    const answeredDate = this.data.survey.answered_survey.answered_date.split(' ')[0];
    const product = this.router.url.includes('followup') ? 'followup' : 'onsite';
    this.guestUrl = `${environment.renderSurvey}?surveyId=${this.surveyId}&surveyCode=${this.data.survey.answered_survey.id}&language=${this.data.language}&startDate=${answeredDate}&endDate=${answeredDate}&guestId=${this.data.survey.guest.id}`;
    this.surveyUrl = `${location.host}/${product.toLowerCase()}/huespedes?guest=${this.data.survey.guest.id}&customer=${
      this.data.customer.id
    }&date=${answeredDate}&utm_campaign=compartir-interno&utm_source=${product}&utm_medium=urlcopy`;
  }

  async getMailingData(answerId: number) {
    try {
      const resp = await this.surveyAnswerService.getMailingData(this.data.customer.id, answerId).toPromise();
      if (resp) {
        this.showContact = true;
        this.customerFrom = `${resp.from_name} <${resp.from_mail}>`;
        this.subject = resp.subject;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getSmartReply(surveyId: number, answerId: number) {
    try {
      const resp = await this.surveyAnswerService.getSmartReplysurveyId(surveyId, answerId).toPromise();
      if (resp && resp.answer.length > 0) {
        this.smartReplyText = `${resp.answer}\n\n\n${resp.signature}`;
      }
    } catch (error) {
      if (error.errors[0].messages[0].text === 'Usage limit exceeded') {
        this.limitExceededError = true;
        this.showContact = true;
      }
      console.error(error);
    } finally {
      this.limitExceeded = this.smartReplyText ? null : this.srMonthLimit;
      this.showLoader = false;
    }
  }

  fetchComments(surveyId, answerId) {
    this.surveyAnswerService.getSurveyComments(surveyId, answerId).subscribe((response: any) => {
      if (response) {
        this.comments = response;
        this.comments.sort((a, b) => parseFloat(a.question.order) - parseFloat(b.question.order));
        this.comments.forEach((item) => {
          this.answersComments.push({
            answer_alternative_id: item.question.alternatives[0].id,
            answer_text: item.comment,
            question_id: item.question.id,
            comment_id: item.comment_id,
          });
        });
      }
    });
  }

  handleExportPDF() {
    this.downloadingPDF = true;
    const body = {
      guest: this.guest,
      survey: this.formattedSurvey,
      language: this.data.language,
    };
    if (this.dataIsReady) {
      this.surveysService.exportPDF(body).subscribe((response: any) => {
        const object = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = object;
        a.download = `Survey_${this.data.survey.answered_survey.id}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(object);
        document.body.removeChild(a);
        this.downloadingPDF = false;
      });
    }
  }

  gettingData(data: any) {
    this.dataIsReady = true;
    this.surveyData = data;
  }

  handleCreateCase($event) {
    this.dialogRef.emitEvent({ data: $event, from: 'create-case' });
  }

  handleCloseContactModal($event) {
    if (!$event) {
      this.showContact = !this.showContact;
      return;
    }
    this.showContact = false;
    this.messageRegistered = $event;
    this.tabIndex = this.isMobile ? 3 : 2;
    this.dialogRef.emitEvent($event);
  }

  updateStyleSections(doc, className: string, styleValue: string, toggleClass = false) {
    const lastElement = doc.getElementsByClassName(className).length - 1;
    const currentSurvey = doc.getElementsByClassName(className)[lastElement];
    if (currentSurvey && !toggleClass) currentSurvey.setAttribute('style', styleValue);
    else if (currentSurvey && toggleClass) currentSurvey.classList.toggle(styleValue);
  }

  handleClose() {
    this.close();
    if (this.answerModal) this.answerModal.close();
  }

  toggleSurvey() {
    this.surveyOpen = !this.surveyOpen;
    if (this.surveyOpen) {
      this.commentsOpen = false;
    }
  }

  toggleComments() {
    this.commentsOpen = !this.commentsOpen;
    if (this.commentsOpen) {
      this.surveyOpen = false;
    }
  }

  async handleContact() {
    this.showContact = true;
  }

  markSurveyContacted($event) {
    this.dialogRef.emitEvent({ data: $event, from: 'mark-survey-contacted' });
  }

  handleChangeTab(event) {
    this.tabIndex = event;
  }

  formatSurvey() {
    this.surveyToFormat = this.data?.survey;
    this.surveyToFormat.guest = {
      ...this.data?.survey.guest,
      show_checkin: this.data?.survey?.show_checkin,
      show_checkout: this.data?.survey?.show_checkout,
      cases: this.data?.survey?.answered_survey?.cases,
      managed: this.data?.survey?.answered_survey?.managed,
    };
    const surveyId = this.data?.survey?.answered_survey?.survey_id;
    const version = 2;
    this.formattedSurvey = Survey.createFromApi({ ...this.surveyToFormat, version, surveyId });
  }

  handleOverlayCasesClick(triggerAction) {
    this.triggerActionsCardOrigin = triggerAction;
    this.shareActionsOpen = !this.shareActionsOpen;
  }

  goToGuestLink() {
    window.open(this.guestUrl, '_blank');
    this.shareActionsOpen = false;
  }

  copySurveyLinkToClipboard() {
    this.clipboard.copy(this.surveyUrl);
    this.alertService.handleAlert(
      'smart_replies.action_button.copied',
      5000,
      'bottom',
      'end',
      'snackbar-panel-success-user-config',
    );
    this.shareActionsOpen = false;
  }

  get guest() {
    return this.data.survey.guest;
  }

  get isTight() {
    return window.innerHeight <= MIN_HEIGHT;
  }

  get isMobile() {
    return this.breakpointService.isMobile();
  }

  get isTablet() {
    return this.breakpointService.isMinorThanMd();
  }

  get isSmallLaptop() {
    return this.breakpointService.isMinorThanXl();
  }

  get slug() {
    return this.router.url.includes('followup') ? 'followup_guests_pdf' : 'onsite_guests_pdf';
  }

  get casesSlug() {
    return this.router.url.includes('followup') ? 'followup_guests_case_create' : 'onsite_guests_case_create';
  }
}
