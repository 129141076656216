import { Component, EventEmitter, HostListener, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';
import { CustomValidators } from 'app/shared/custom-validators';
import { UtilService } from 'app/shared/util.service';
import PhoneCodesWorldWide from 'app/shared/utils/phones-codes-world-wide';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { RolesActionsModalComponent } from '../../roles-actions-modal/roles-actions-modal.component';
import { RestoreAccessComponent } from '../../actions/restore-access/restore-access.component';
import { phoneValidator } from '../../../../shared/validators/phone-validator';
import { phonePatternsMap } from '../../../../shared/utils/phone-patterns-map';
import { Location } from '@angular/common';

@Component({
  selector: 'mh-personal-information',
  templateUrl: './personal-information.component.html',
})
export class PersonalInformationComponent implements OnInit {
  @Input() data;
  @Input() isEdit = false;

  @Input() progressRate;
  @Input() personalAdjusts;

  @Output() validForm = new EventEmitter();
  @Output() updateUser = new EventEmitter();
  @Output() updateIndex = new EventEmitter();

  form: FormGroup;
  object = Object;
  roles = [];
  departments = [];
  languages = [];
  phonesCodes = PhoneCodesWorldWide;
  patchUser = false;
  image;
  subscription: Subscription;
  isSuperAdmin;
  rolesList: { id: number; name: string }[] = [];
  selectedCode = null;
  isDropdownOpen = false;
  cellphoneCountryCodeSuscription: Subscription;
  searchTerm: any;
  filteredCodes: any[] = [];
  readonly imagesPath = 'https://mh-statics.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/user-manager/flags/';

  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private userManagementService: UsersManagmentService,
    private modalService: ModalService,
    private location: Location,
  ) {}

  getColClass( controlName: string ) {
    const controlsFullWidth: string[] = ['separator'];
    const defaultWidth: string = '1/3'    

    if( controlsFullWidth.includes(controlName) ) return 'full'
    else return defaultWidth;
  }

  ngOnInit(): void {
    this.phonesCodes = this.phonesCodes.sort((a, b) => {
      const codePhoneA = parseFloat(a.code_phone.replace('-', ''));
      const codePhoneB = parseFloat(b.code_phone.replace('-', ''));
      return codePhoneA - codePhoneB;
    });
    this.isSuperAdmin = this.utilService.currentUser.isSuperAdmin();
    this.patchUser = this.data?.id;
    this.form = this.fb.group({
      name: [this.data?.first_name || this.data?.name || '', [Validators.required]],
      lastName: [this.data?.last_name || this.data?.lastName || '', [Validators.required]],
      email: [this.data?.email || this.data || '', [Validators.required, CustomValidators.email]],
      cellphoneCountryCode: [this.data?.mobile_country_code || this.data?.cellphoneCountryCode || ''],
      cellphone: [this.data?.mobile_number || this.data?.cellphone || ''],
      birthdate: [
        (this.data?.birth_date && moment(this.data?.birth_date, 'YYYY-MM-DD').format('YYYY-MM-DD')) ||
          this.data?.birthdate ||
          '',
      ],
      job: [this.data?.company_position, [Validators.required]],
      department: [this.data?.department?.id || '', [Validators.required]],
      jobType: [this.data?.role?.id],
      role: [this.data?.user_role?.id || ''],
      language: [this.data?.language_id || this.data?.language || 1, [Validators.required]],
      profilePhotoRaw: [this.data?.profile_photo || this.data?.profilePhotoRaw],
    });

    if (this.isSuperAdmin) {
      this.form.addControl('hubspot_id', new FormControl(this.data?.hubspot_id || ''));
      this.form.addControl('cs_executive', new FormControl(this.data?.cs_executive || false));
    }

    if (this.form.get('email').value !== '') {
      this.form.get('email').disable();
    }

    if (this.personalAdjusts) {
      this.form.get('role').disable();
    }

    // si existe un couuntryCode, entonces se busca el objeto del pais para setearlo al selectedCode, asi el select puede mostrar los paises
    if( this.form.get('cellphoneCountryCode')?.value ) this.selectedCode =  this.phonesCodes.find( phone => (phone.code_phone as string) === (this.form.get('cellphoneCountryCode')?.value as string).substring(1) ) 
    this.selectedCode ? this.handleCountryCode() : this.handleEmptyCountryCode();
    this.form.get('cellphone')?.updateValueAndValidity();

    this.userManagementService.getRolesAndDeparments().subscribe((result: { roles: any[]; deparments: any[] }) => {
      const { roles, deparments } = result;
      this.roles = roles;      
      this.departments = deparments.sort((a, b) => a.code.localeCompare(b.code));
    });

    this.userManagementService.getRoles().subscribe((result: { id: number; name: string }[]) => {
      this.rolesList = result;
    });

    this.languages = this.utilService.languages;
    if (this.patchUser) {
      this.validForm.emit(true);
    }

    this.cellphoneCountryCodeSuscription = this.form.get('cellphoneCountryCode')?.valueChanges.subscribe((countryCode) => {
      countryCode ? 
      this.handleCountryCode() : 
      this.handleEmptyCountryCode();

      this.form.get('cellphone')?.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.cellphoneCountryCodeSuscription?.unsubscribe();    
  }

  handleCountryCode() {
    this.form.get('cellphone')?.setValidators([Validators.required, phoneValidator(phonePatternsMap)]) 
    this.form.get('cellphone')?.enable();
  }

  handleEmptyCountryCode() {
    this.form.get('cellphone')?.clearValidators();
    this.form.get('cellphone')?.setValue(null);
    this.form.get('cellphone')?.disable();

    this.form.get('cellphoneCountryCode')?.patchValue(null, { emitEvent: false });
    this.selectedCode = null;
  }

  selectCode(code: any): void {
    this.selectedCode = code;
    code ?
    this.form.get('cellphoneCountryCode')?.setValue('+' + code.code_phone ) : 
    this.handleEmptyCountryCode();
    this.isDropdownOpen = false;
    this.form.get('cellphone').updateValueAndValidity(); 
  }

  goToBack() {
    this.location.back();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      this.filteredCodes = this.phonesCodes;
    }
  }

  filterCodes() {
    let searchTerm = this.form.get('cellphoneCountryCode')?.value;
    if (searchTerm) {
      searchTerm = searchTerm?.replace(/^\+/, '');
      this.filteredCodes = this.phonesCodes.filter(code =>
        code.country.toLowerCase().includes(searchTerm.toLowerCase()) ||
        code.code_phone.includes(searchTerm)
      );
    } else {
      this.filteredCodes = this.phonesCodes;
    }
  }

  verifyPhoneNumber() {
    if( this.form.get('cellphoneCountryCode')?.value ) this.selectedCode =  (this.phonesCodes.find( phone => (phone.code_phone as string) === (this.form.get('cellphoneCountryCode')?.value as string).substring(1)) || null );
      
    if ( !this.selectedCode && this.form.get('cellphoneCountryCode')?.value ) {
      this.form.get('cellphone')?.setErrors({ 'invalidCountryCode': true })
      return false
    };
      
    return true;
  }

  get controls() {
    const staticNonPublicControls = ['phoneCountryCode', 'cellphoneCountryCode', 'flagAdmin', 'profilePhotoRaw'];
    const nonPublicControls = this.isSuperAdmin ? staticNonPublicControls : [...staticNonPublicControls, 'pipedriveId', 'separator'];
    const controls = Object.keys(this.form.controls).filter((key) => !nonPublicControls.includes(key));
    return this.isSuperAdmin ? this.setSeparatorBeforeAdminControls(controls) : controls ;
  }

  setSeparatorBeforeAdminControls(controls){
    controls.splice(controls.indexOf('hubspot_id'), 0, 'separator'); 
    return controls
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const target = event.target as HTMLElement;
    const clickedInside = (target.closest('.tw-relative') !== null);

    if (!clickedInside && this.isDropdownOpen) {
      this.isDropdownOpen = false;
    }
  }

  changeProfilePhoto(event) {
    this.image = event;
    this.form.get('profilePhotoRaw').setValue(this.image || '');
  }

  onClickToggle(toggle) {
    this.form.get('flagAdmin').setValue(toggle.value);
  }

  numbersOnly(event) {
    const regExp = /^([0-9])$/;
    const result = regExp.test(event.key);
    return result;
  }

  emitUser() {
    if(!this.verifyPhoneNumber()) return 

    let userToEmit = {
      ...this.form.value,
      last_name: this.form.get('lastName').value || '',
      email: this.form.get('email').value || '',
      department: this.departments.find((department) => department.id === this.form.get('department').value),
      role: this.roles.find((role) => role.id === this.form.get('jobType').value),
      user_role: this.rolesList.find((role) => role.id === this.form.get('role').value),
      company_position: this.form.get('job').value,
      id: this.data.id,
      flag_admin: this.form.get('role').value === 1,
      mobile_number: this.form.get('cellphone').value || '',
      mobile_country_code: this.form.get('cellphoneCountryCode').value || '',
      flag_super_admin: this.data?.flag_super_admin,
      birth_date: this.form.get('birthdate').value || '',
      first_name: this.form.get('name').value || '',
      language_id: this.form.get('language').value || 1,
    };

    if (this.form.get('profilePhotoRaw').value?.data) {
      userToEmit = {
        ...userToEmit,
        profile_photo_raw: this.form.get('profilePhotoRaw').value || '',
      };
    }
    
    this.updateUser.emit(userToEmit);
  }

  setBirthdate(date) {
    this.form.get('birthdate').setValue(date);
  }

  updateIndexEmit(value) {
    this.emitUser();
    this.updateIndex.next(value);
  }

  openRolesActionsModal() {
    this.modalService.open(RolesActionsModalComponent, null, ['overlay-panel', 'xxlg', '!tw-h-auto']);
  }

  editPassword() {
    const modalComponent = RestoreAccessComponent;
    const modalClass = ['overlay-panel', '!tw-h-auto'];
    this.modalService.open(
      modalComponent,
      {
        data: {
          state: 2,
          user: { id: this.data.id, first_name: this.data.first_name, last_name: this.data.last_name },
          userIds: this.data.id,
        },
      },
      modalClass,
    );
  }
}
